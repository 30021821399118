import React from "react"
import { graphql, Link } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import Seo from "../components/seo"
import { PrismicRichText, PrismicImage } from "@prismicio/react"
// import ArticleExhibition from "../components/ArticleExhibition"
import { _linkResolver } from "../core/utils"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import Exhibition from "./Exhibition"
import { useSiteMetadata } from "../hooks/useSiteMetadata"
import Exhibitions from "../components/Exhibitions"
import News from "../components/News"

export const pageQuery = graphql`
  query ArtistBySlug($uid: String!) {
    prismicArtist(uid: { eq: $uid }) {
      _previewable
      data {
        seo_title {
          text
        }
        seo_texte {
          text
        }
        seo_image {
          url
        }
        title {
          text
        }
        texte {
          richText
        }
        image_featured {
          url
          alt
          dimensions {
            width
            height
          }
          gatsbyImageData(width: 2000)
        }
        exhibitions {
          item {
            document {
              ... on PrismicExhibition {
                uid
                type
                data {
                  title {
                    text
                  }
                  date_from(formatString: "DD.MM.YYYY")
                  date_to(formatString: "DD.MM.YYYY")
                  image_featured {
                    url
                    alt
                    dimensions {
                      width
                      height
                    }
                    gatsbyImageData(width: 200)
                  }
                  artist {
                    document {
                      ... on PrismicArtist {
                        id
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Artist = ({ data: { prismicArtist } }) => {
  const {
    seo_title,
    seo_texte,
    seo_image,
    title,
    texte,
    image_featured,
    exhibitions,
  } = prismicArtist.data

  let concatAndDeDuplicateObjects = (key, ...arrs) =>
    []
      .concat(...arrs)
      .reduce(
        (a, b) => (!a.filter(c => b[key] === c[key]).length ? [...a, b] : a),
        []
      )

  const artistExhibitions = exhibitions.map(el => el.item.document)
  const { allExhibitions } = useSiteMetadata()
  const allExhibitionsExceptArtistsExhibitions = concatAndDeDuplicateObjects(
    "uid",
    artistExhibitions,
    allExhibitions
  )
  // console.log(allExhibitionsExceptArtistsExhibitions)
  return (
    <div>
      <Seo
        pageTitle={seo_title.text}
        pageDescription={seo_texte.text}
        pageBanner={seo_image.url}
        template="template-artist"
        page={true}
      />
      <div className="md:grid grid-cols-3 gap-lg">
        <article className="mb-lg col-span-2">
          <div className="header mb-sm">
            <h1>{title.text}</h1>
          </div>

          <figure className="mb-sm- mb-md">
            <GatsbyImage
              image={getImage(image_featured)}
              alt={image_featured.alt ? image_featured.alt : title.text}
            />
          </figure>

          <div className="texte text-justify">
            <PrismicRichText
              field={texte.richText}
              components={{
                image: ({ node }) => (
                  <figure>
                    <PrismicImage field={node} alt={node.alt} />
                    <figcaption>{node.alt}</figcaption>
                  </figure>
                ),
              }}
            />
          </div>
        </article>

        <div className="md:pl-lg-">
          <News />
          {/* <Exhibitions
            input={allExhibitions}
            // except={}
          /> */}
        </div>
      </div>
    </div>
  )
}

export default withPrismicPreview(Artist)
