import React from "react"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import clsx from "clsx"
import { _linkResolver, _sanitizeExhibitions } from "../core/utils"
import Exhibitions from "../components/Exhibitions"
import News from "../components/News"

export const pageQuery = graphql`
  query PageQuery($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      data {
        seo_title {
          text
        }
        seo_texte {
          text
        }
        seo_image {
          url
        }
        title {
          text
        }
        body {
          ... on PrismicPageDataBodyListArtist {
            slice_type
            items {
              item {
                document {
                  ... on PrismicArtist {
                    uid
                    type
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyListExhibition {
            slice_type
            items {
              item {
                document {
                  ... on PrismicExhibition {
                    uid
                    type
                    data {
                      title {
                        text
                      }
                      date_from(formatString: "DD.MM.YYYY")
                      date_to(formatString: "DD.MM.YYYY")
                      image_featured {
                        url
                        alt
                        dimensions {
                          width
                          height
                        }
                        gatsbyImageData(width: 200)
                      }
                      artist {
                        document {
                          ... on PrismicArtist {
                            id
                            data {
                              title {
                                text
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyListFair {
            slice_type
            items {
              item {
                document {
                  ... on PrismicFair {
                    uid
                    type
                    data {
                      title {
                        text
                      }
                      date_from(formatString: "DD.MM.YYYY")
                      date_to(formatString: "DD.MM.YYYY")
                      image_featured {
                        url
                        alt
                        dimensions {
                          width
                          height
                        }
                        gatsbyImageData(width: 200)
                      }
                      artist {
                        document {
                          ... on PrismicArtist {
                            id
                            data {
                              title {
                                text
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Page = ({ data: { prismicPage } }) => {
  const { seo_title, seo_texte, seo_image, body } = prismicPage.data
  return (
    <div>
      <Seo
        pageTitle={seo_title.text}
        pageDescription={seo_texte.text}
        pageBanner={seo_image.url}
        template="template-page"
        page={true}
      />

      <div className="md:grid grid-cols-3 gap-lg">
        <div className="mb-lg col-span-2">
          {body.map((item, i) => (
            <section
              className={clsx("module", `module--${item.slice_type}`)}
              key={i}
            >
              {item.slice_type === "list_artist" && (
                <ul className="list_artist">
                  {item.items.map(({ item }) => (
                    <li key={item.uid}>
                      <Link to={_linkResolver(item.document)}>
                        {item.document.data.title.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
              {item.slice_type === "list_exhibition" && (
                // <pre>
                //   {JSON.stringify(_sanitizeExhibitions(item.items), null, 2)}
                // </pre>
                <Exhibitions input={_sanitizeExhibitions(item.items)} />
              )}
              {item.slice_type === "list_fair" && (
                // <pre>{JSON.stringify(item, null, 2)}</pre>
                <Exhibitions input={_sanitizeExhibitions(item.items)} />
              )}
            </section>
          ))}
        </div>
        <div className="">
          <News />
        </div>
      </div>
    </div>
  )
}

export default withPrismicPreview(Page)
