import React from "react"
import { Link } from "gatsby"
import { _linkResolver } from "../core/utils"
import HoverImage from "./ui/HoverImage"
import clsx from "clsx"
import styled from "styled-components"

const Li = styled.li`
  a {
    // display: block;
  }
  &:hover a {
    // background: red;
    color: black;
  }
`

const Exhibitions = ({ artist = "", input, except }) => {
  // console.log(input)
  const list = except ? input.filter(el => el.uid !== except.uid) : input
  // console.log(except)
  // console.log(input)
  return (
    <div className="exhibitions h-full">
      <div className="md:sticky- md:top-md- scroller-">
        <ul>
          {list.map((item, i) => (
            <Li
              key={item.uid}
              className={clsx(
                "mb-sm relative ",
                `exhibition-item exhibition-item-${item.uid}`
              )}
            >
              <Link to={_linkResolver(item)}>
                <div className="pointer-events-none ">
                  {item.data.artist && item.data.artist.document && (
                    <div>{item.data.artist.document.data.title.text}</div>
                  )}
                  <h2 className="pointer-events-none">
                    <span>{item.data.title.text}</span>
                  </h2>

                  {item.data.date_from && item.data.date_to && (
                    <div className="date pointer-events-none">{`${item.data.date_from} — ${item.data.date_to}`}</div>
                  )}
                </div>
                {item.data.image_featured && item.data.image_featured.url && (
                  <HoverImage
                    parent={`exhibition-item-${item.uid}`}
                    image={item.data.image_featured}
                    alt={item.data.title.text}
                  />
                )}
              </Link>
            </Li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Exhibitions
